export const ErrorCodes = [
    { code: 401, message: "unauthorized-error" },
    { code: 403, message: "forbidden-error" },
    { code: 404, message: "not-found-error" },
    { code: 501, message: "server-error" },
    { code: 502, message: "network-error" },
    { code: 503, message: "service-unavailable" },
];

export const TranslateErrorMessages = (code: number): string => {
    var errorCode = ErrorCodes.find((x) => x.code === code);
    if (errorCode) return errorCode.message;
    return "unknown-error";
};
