import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthUserType, MenuType } from "src/auth/types";
import { IError } from "src/types/error";

type userPayload = {
    user: AuthUserType | null;
    menus: MenuType[];
    pending: boolean;
};

interface AuthState {
    initialized: boolean;
    loading: boolean;
    busy: boolean;
    modalIsOpen: boolean;
    authenticated: boolean;
    payload: userPayload;
    Error?: IError;
}

const initialState: AuthState = {
    modalIsOpen: false,
    authenticated: false,
    payload: { user: null, menus: [], pending: false },
    initialized: false,
    loading: true,
    busy: false,
};

const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        InitUser: (state, action: PayloadAction<userPayload>) => {
            state.payload = action.payload;
            state.initialized = true;
            state.loading = false;
            state.authenticated = action.payload.user != null;
            state.payload.user = action.payload.user;
            state.payload.menus = action.payload.menus;
            state.payload.pending = action.payload.pending;
        },
        setAuthModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpen = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setError: (state, action: PayloadAction<IError | undefined>) => {
            state.Error = action.payload;

            //state.loading = false;
            //state.initialized = true;
        },
        setBusy: (state, action: PayloadAction<boolean>) => {
            state.busy = action.payload;
        },
    },
});

export const { InitUser, setAuthModalIsOpen, setLoading, setError, setBusy } = AuthSlice.actions;

export default AuthSlice.reducer;
