import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type TProps = {
  children: ReactNode;
  append?: string;
};

export const T: React.FC<TProps> = ({ children, append }) => {
  const { t } = useTranslation();
  if (typeof children === 'string') {
    children = t(children);
  }

  return (
    <>
      {children}
      {append && <>&nbsp;{append}</>}
    </>
  );
};
