// @mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
// hooks
import { useOffSetTop } from "src/hooks/use-off-set-top";
import { useResponsive } from "src/hooks/use-responsive";
// theme
import { bgBlur } from "src/theme/css";
// routes
// components
import Logo from "src/components/logo";
//
import { HEADER } from "../config-layout";
import { navConfig } from "./config-navigation";
import NavDesktop from "./nav/desktop";
import NavMobile from "./nav/mobile";
//
import { HeaderShadow, LoginButton } from "../_common";
import SettingsButton from "../_common/settings-button";

// ----------------------------------------------------------------------

export default function Header() {
    const theme = useTheme();

    const mdUp = useResponsive("up", "md");

    const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    },
                    transition: theme.transitions.create(["height"], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(offsetTop && {
                        ...bgBlur({
                            color: theme.palette.background.default,
                        }),
                        height: {
                            md: HEADER.H_DESKTOP_OFFSET,
                        },
                    }),
                }}>
                <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
                    <Logo />

                    <Box sx={{ flexGrow: 1 }} />

                    {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig.filter((x) => x.desktopRender)} />}

                    <Stack alignItems="center" direction={{ xs: "row", md: "row-reverse" }}>
                        {mdUp && <LoginButton />}

                        <SettingsButton
                            sx={{
                                ml: { xs: 1, md: 0 },
                                mr: { md: 2 },
                            }}
                        />

                        {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
                    </Stack>
                </Container>
            </Toolbar>

            {offsetTop && <HeaderShadow />}
        </AppBar>
    );
}
