// routes
import toast from 'react-hot-toast';
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
const accessTokenStorageKey = 'accessToken';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------
export const getAccessToken = () => sessionStorage.getItem(accessTokenStorageKey);
export const isValidToken = (accessToken: string | null) => {
  if (!accessToken) return false;

  try {
    const decoded = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  } catch (error) {
    return false;
  }
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  // Check if parts has at least two elements
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift(); // Use optional chaining
  }

  return undefined; // Return undefined if the cookie is not found
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    toast.error('Token expired');

    sessionStorage.removeItem(accessTokenStorageKey);

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem(accessTokenStorageKey, accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    // tokenExpired(exp);
  } else {
    sessionStorage.removeItem(accessTokenStorageKey);

    delete axios.defaults.headers.common.Authorization;
  }
};
