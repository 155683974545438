import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticationService } from "src/api/authentication/authentication-service-hook";
import { enums } from "src/api/enums";
import { getAccessToken, setSession } from "src/api/utils";
import { InitUser, setAuthModalIsOpen, setBusy, setError, setLoading } from "src/redux/slice/auth-slice";
import { AuthState } from "src/redux/stores/auth-store";
import { AuthUserType } from "../types";

export const UseAuth = () => {
    const dispatch = useDispatch();
    const authService = useAuthenticationService();

    const { t } = useTranslation();
    const { Auth } = useSelector((state: AuthState) => state);

    const resetState = useCallback(() => {
        setSession(null);
        dispatch(
            InitUser({
                menus: [],
                user: null,
                pending: false,
            })
        );
    }, [dispatch, Auth]);

    const setUserSession = useCallback(
        (result: any) => {
            const { user, menus, accessToken } = result;

            setSession(accessToken);
            dispatch(
                InitUser({
                    user,
                    menus,
                    pending: enums.USER_STATUS_OPTIONS.isPending(user.statusId),
                })
            );
        },
        [dispatch]
    );

    const initUserInfo = useCallback(async () => {
        await authService
            .initUserInfo()
            .then((result) => {
                dispatch(setError(undefined));
                setUserSession(result);
            })
            .catch((err) => dispatch(setError(new Error(err))))
            .finally(() => {
                dispatch(setLoading(false));
                dispatch(setBusy(false));
            });
    }, [setUserSession, dispatch, authService]);

    const resetAuth = async () => {
        dispatch(setBusy(true));
        await initUserInfo();
    };

    const initialize = useCallback(async () => {
        try {
            const accessToken = getAccessToken();
            setSession(accessToken);
            return await initUserInfo();
        } catch (error) {
            toast.error(error);
            resetState();
        }
    }, [initUserInfo, resetState]);

    useEffect(() => {
        if (Auth.initialized || Auth.Error) return;
        initialize();
    }, [Auth, initialize]);

    const login = async (email: string, password: string, onError: (error: string) => void) => {
        await authService
            .login(email, password)
            .then((result: any) => {
                toast.success(t("login-successful"));
                setUserSession(result);
            })
            .catch((err) => onError(err.errorMessage));
    };

    const register = async (email: string, password: string, firstName: string, lastName: string, onError: (error: string) => void) => {
        await authService
            .register(email, password, firstName, lastName)
            .then(setUserSession)
            .catch((err) => onError(err.errorMessage));
    };

    const updateUserInfo = async (user: AuthUserType) =>
        setUserSession({ user: user, menus: Auth.payload.menus, accessToken: getAccessToken() });

    const toggleAuthModalStatus = () => {
        dispatch(setAuthModalIsOpen(!Auth.modalIsOpen));
    };

    return {
        login,
        register,
        logout: resetState,
        updateUserInfo,
        toggleAuthModalStatus,
        resetAuth,
        user: Auth.payload.user,
        initialized: Auth.initialized,
        menus: Auth.payload.menus,
        modalIsOpen: Auth.modalIsOpen,
        pending: Auth.payload.pending,
        loading: Auth.loading,
        busy: Auth.busy,
        error: Auth.Error,
        authenticated: Auth.payload.user !== null,
        unauthenticated: Auth.payload.user === null,
    };
};
