import { LoginDTO } from "../../models/auth/login-dto";
import { RegisterDTO } from "../../models/auth/register-dto";
import { post } from "../api";
import Urls from "../end-points";

export const AuthApi = {
    initUserInfo: () => post(Urls.auth.userInfo, {}),
    login: (data: LoginDTO) => post(Urls.auth.login, data),
    register: (data: RegisterDTO) => post(Urls.auth.register, data),
};
