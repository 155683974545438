// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
  },
  product: {
    details: (id: number) => `/products/${id}`,
  },
  category: {
    root: `/categories`,
    url: (serial: number | undefined) => (serial ? `/categories/${serial}` : `/categories`),
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    blank: `${ROOTS.DASHBOARD}/blank`,

    product: {
      details: (id: number) => `${ROOTS.DASHBOARD}/products/${id}`,
    },
    category: {
      root: `${ROOTS.DASHBOARD}/categories`,
      url: (serial: number | undefined) =>
        serial ? `${ROOTS.DASHBOARD}/categories/${serial}` : `${ROOTS.DASHBOARD}/categories`,
    },
    sessions: {
      root: `${ROOTS.DASHBOARD}/sessions`,
    },

    service: {
      root: `${ROOTS.DASHBOARD}/services`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      detail: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/detail`,
    },
    wallet: {
      root: `${ROOTS.DASHBOARD}/wallet`,
    },
  },
};
