export const enums = {
  roles: [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
  ],

  USER_STATUS_OPTIONS: {
    values: [
      { value: 'active', label: 'Active' },
      { value: 'pending', label: 'Pending' },
      { value: 'banned', label: 'Banned' },
      { value: 'rejected', label: 'Rejected' },
    ],
    isActive: (value: string | undefined) => value === 'active',
    isPending: (value: string | undefined) => value === 'pending',
  },
};
