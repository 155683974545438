import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
// components
import { useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

type ThemeButtonProps = {
  option: string;
};

export default function SettingsButton({ sx }: Props) {
  const settings = useSettingsContext();
  const [selectedTheme, setSelectedTheme] = useState<string>(settings.themeMode);

  const changeTheme = (newValue: string) => {
    settings.onUpdate('themeMode', newValue);
    setSelectedTheme(newValue);
  };

  const ThemeButton = ({ option }: ThemeButtonProps) => (
    <IconButton
      key={option}
      onClick={() => changeTheme(selectedTheme === 'light' ? 'dark' : 'light')}
      sx={{
        width: 1,

        '& .svg-color': {
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
        },
      }}
    >
      <SvgColor src={`/assets/icons/setting/ic_${option === 'dark' ? 'moon' : 'sun'}.svg`} />
    </IconButton>
  );

  return (
    <Box component={m.div}>
      <ThemeButton option={selectedTheme} />
    </Box>
  );
}
