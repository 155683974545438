import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Box, Card, Container, Grid, Stack } from "@mui/material";
import FormProvider from "../components/hook-form/form-provider";
import { RHFTextField } from "../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { IUser } from "../types/user";

export const CreateUser = () => {
    const [User, setUser] = useState<IUser>({
        ActiveUserCount: 1,
        Enable: false,
        ExpireDate: 0,
        TotalVolume: 0,
        UsageVolume: 0,
        uuid: "",
    });

    const defaultValues: IUser = {
        ActiveUserCount: User.ActiveUserCount || 1,
        Enable: User.Enable || false,
        ExpireDate: User.ExpireDate || 0,
        TotalVolume: User.TotalVolume || 0,
        UsageVolume: User.UsageVolume || 0,
        uuid: User.uuid || uuidv4(),
    };

    const UserValidation = Yup.object().shape({
        ActiveUserCount: Yup.number().required("Active User Count is required"),
        Enable: Yup.boolean().required(),
        ExpireDate: Yup.number().required("Expire Date is required"),
        TotalVolume: Yup.number().required("Total Volume is required"),
        UsageVolume: Yup.number().required("Usage Volume is required"),
        uuid: Yup.string().required("uuid is required"),
    });

    const methods = useForm<IUser>({
        resolver: yupResolver(UserValidation),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = useCallback(async (data: IUser) => {
        console.log(data);
    }, []);

    return (
        <Container maxWidth="lg">
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3 }}>
                            <Box>
                                <Box
                                    rowGap={3}
                                    columnGap={3}
                                    display="grid"
                                    gridTemplateColumns={{
                                        xs: "repeat(1, 1fr)",
                                        sm: "repeat(2, 1fr)",
                                    }}>
                                    <RHFTextField name="ActiveUserCount" label="Active User Count" />
                                    <RHFTextField name="ExpireDate" label="Expire Date" />
                                    <RHFTextField name="TotalVolume" label="Total Volume" />
                                    <RHFTextField name="UsageVolume" label="Usage Volume" />
                                </Box>
                                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                                    <RHFTextField name="uuid" label="UUID" />

                                    <Stack justifyContent="space-between" flexDirection="row" sx={{ width: "100%" }}>
                                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                            Save Changes
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </FormProvider>
        </Container>
    );
};
