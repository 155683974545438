import { Outlet, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main";
import { Main } from "../pages/main";
import { CreateUser } from "../pages/create-user";

export default function Router() {
    return useRoutes([
        {
            path: "admin",
            element: (
                <MainLayout>
                    <Outlet />
                </MainLayout>
            ),
            children: [
                { path: "", element: <Main /> },
                { path: "newuser", element: <CreateUser /> },
            ],
        },
    ]);
}
