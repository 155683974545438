import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/auth-slice";

export const stores = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    reducer: {
        Auth: authSlice,
    },
});

export type RootState = ReturnType<typeof stores.getState>;
export type AppDispatch = typeof stores.dispatch;
