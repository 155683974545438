import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export const navConfig = [
    {
        title: "home",
        icon: <Iconify icon="solar:home-2-bold-duotone" />,
        path: "/",
        desktopRender: true,
    },
    {
        title: "login",
        icon: <Iconify icon="hugeicons:connect" />,
        path: "/auth/login",
        desktopRender: false,
    },
];
