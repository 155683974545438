import { AuthApi } from "./authentication-api";

export const useAuthenticationService = () => {
    const initUserInfo = () => AuthApi.initUserInfo();
    const login = (email: string, password: string) => AuthApi.login({ email, password });
    const register = (email: string, password: string, firstName: string, lastName: string) =>
        AuthApi.register({ email, firstName, lastName, password });

    return { initUserInfo, login, register };
};
