// @mui
import { SxProps, Theme } from '@mui/material/styles';
// routes
import LoadingButton from '@mui/lab/LoadingButton';
import { UseAuth } from 'src/auth/hooks';
import { T } from 'src/components/text';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function LoginButton({ sx }: Props) {
  const { authenticated, loading } = UseAuth();

  if (authenticated)
    return (
      <LoadingButton
        loading={loading}
        component={RouterLink}
        href={paths.dashboard.root}
        variant="outlined"
        sx={{ mr: 1, ...sx }}
      >
        <T>dashboard</T>
      </LoadingButton>
    );
  return (
    <LoadingButton
      loading={loading}
      component={RouterLink}
      href={paths.auth.login}
      variant="outlined"
      sx={{ mr: 1, ...sx }}
    >
      <T>login</T>
    </LoadingButton>
  );
}
