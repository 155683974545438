const controllers = {
    users: "users",
    auth: "auth",
};

const Urls = {
    users: {
        initUsers: `${controllers.users}/`,
    },
    auth: {
        userInfo: "",
        login: "",
        register: "",
    },
};
export default Urls;
