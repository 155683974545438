import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SettingsProvider } from "src/components/settings";
import Router from "./routes";
import ThemeProvider from "./theme";
import MotionLazy from "src/components/animate/motion-lazy";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";
import { Provider as ReduxProvider } from "react-redux";
import ToasterProvider from "./providers/toaster-provider";

import { stores } from "./redux/stores/store";

function App() {
    return (
        <ReduxProvider store={stores}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider
                    defaultSettings={{
                        themeMode: "light", // 'light' | 'dark'
                        themeDirection: "ltr", //  'rtl' | 'ltr'
                        themeContrast: "default", // 'default' | 'bold'
                        themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
                        themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                        themeStretch: false,
                    }}>
                    <ThemeProvider>
                        <MotionLazy>
                            <SnackbarProvider>
                                <ToasterProvider />
                                <Router />
                            </SnackbarProvider>
                        </MotionLazy>
                    </ThemeProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </ReduxProvider>
    );
}

export default App;
